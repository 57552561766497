import { CellSuccessProps, CellFailureProps, Metadata } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import { default as EmptyComponent } from '../Library/Empty/Empty'

export const QUERY = gql`
  query FindCardForIframe($id: Int!) {
    card: informerListCard(id: $id) {
      id
      url
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <div className="p-8">
    <EmptyComponent title="Embedded page not found" />
  </div>
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({ card }: CellSuccessProps) => {
  const isPublicPhoto = /^https?:\/\/.*\.(jpg|jpeg|png|gif|webp)$/i.test(
    card.url,
  )
  return (
    <>
      <Metadata title={card.name} description={card.name} />
      {isPublicPhoto ? (
        <img
          src={card.url}
          alt={card.name}
          className="w-full h-screen object-contain p-10"
        />
      ) : (
        <iframe
          title={card.name}
          src={card.url}
          className="w-full h-screen"
        ></iframe>
      )}
    </>
  )
}
