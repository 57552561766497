import { FLAG_FEATURES } from 'api/src/common/enums'

import CardIframeCell from 'src/components/CardIframeCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const EmbedPage = ({ cardId }) => {
  return (
    <>
      <FeatureToggle
        feature={FLAG_FEATURES.EMBEDDED_CARDS}
        InactiveComponent={<FeatureDenied />}
      >
        <CardIframeCell id={cardId} />
      </FeatureToggle>
    </>
  )
}

export default EmbedPage
